.navContainer {
    display: flex;
    position: fixed;
    width: 100vw;
    max-width: 100%;
    height:100px ;
    background-color: #141B1F;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;    z-index: 9999;
    margin-bottom: 20px;
}

.links{
    display: flex;
    justify-content: space-around;
    margin-right: 30px;
}


.mobileJay{
    display: none;
}

.jay {
    display: flex;
}
.jay img {
    height: 80px;
    width: 80px;
    margin: auto;
}

.links a {
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: min-content;
    color: #D6AA32;
    height: fit-content;
    text-decoration: none;
    margin: auto auto auto auto;
}


.links li {
    margin: auto;
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: min-content;
    color: #D6AA32;
    font-size: 2.0em;
    height: fit-content;
    width: min-content;
    text-decoration: none;
    margin-right: 40px;
}

.mobileNav {
    display: none;
}
.mobileLinks {
    display: none;
}

@media only screen and (max-width: 1200px) {


    .navContainer{
        display: none;
    }
    .mobileNav {
        position:absolute;
        width: 95vw;
        display: flex;
        height: 100px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 1px 0px,
          rgba(14, 30, 37, 0.32) 0px 2px 1px 0px;
        justify-content: space-between;
      }
    

    .mobileNavContainer {
        display: flex;
        position: fixed;
        flex-direction: column;
        width: 100vw;
        max-width: 100%;
        height:100px ;
        background-color: #141B1F;
        justify-content: space-between;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;    z-index: 9999;
        margin-bottom: 20px;
    }

    .mobileLinks {
        display: flex;
        flex-direction: column;
        position: fixed;
        justify-content: flex-start;
        width: 100vw;
        height: 100vh;
        flex-direction: row;
        margin-top: 100px;
        color: black;
        background-color: #141B1F;
    }

    .mobileLinks ul {
        display: flex;
        flex-direction: column;
        width: 90vw;
        padding: 0px;
        height: fit-content;
    }
    .mobileLinks li{
        margin: 30px auto 20px auto;

    }
    .mobileLinks a{
        text-decoration: none;
        cursor: pointer;
        color:#D6AA32 ;
        font-size: 3em;
        height: min-content;
        margin: 30px auto 20px auto;
    }
    .mobileJay{
        display: flex;
    }
    .mobileJay img {
        height: 80px;
        width: 80px;
        margin: auto;
    }
}