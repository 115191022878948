.aboutContainer {
  display: flex;
  padding-top: 150px;
  margin-top: 225px;
  height: fit-content;
  width: 100vw;
  justify-content: center;
  max-width: 100%;
}

.about {
  display: flex;
  flex-direction: row;
  margin-bottom: 200px;
}
.gitImg {
  width: 400px;
  height: 473.99px;
  order: 1;
}

.gitImg img {
  width: 360px;
  height: 426.99px;
  order: 1;
}

.bold {
  font-size: 1.5rem;
  color: #d6aa32;
  font-weight: 700;
}

.underlined {
  text-decoration: none;
  width: fit-content;
  background-image: linear-gradient(#d6aa32, #d6aa32);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 0% 3px;
  transition: background-size 500ms ease-in-out;
}

.underlined:hover {
  background-size: 100% 3px;
  color: #d6aa32;
}

.list li {
  list-style-type: none;
  margin-bottom: 10px;
}
.list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.bolded {
  color: #d6aa32;
  font-size: 2em;
}

.h2style {
  font-size: 4em !important;
  color: #ffff !important;
  width: fit-content;
  margin-top: 0px;
}

.text {
  font-family: "Courier New", Courier, monospace;
  color: #ffdf88;
  font-size: 1.5em;
}

.aboutInfo {
  width: 40vw;
}
.abText {
  color: #ffdf88;
  font-size: 1.5em;
  margin-bottom: 50px;
  text-align: left;
}
.projectsTitle {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
}

.projectsContainer {
  display: flex;
  margin: auto;
  padding-top: 150px;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  max-width: 100%;
}

.projectImg {
  position: relative;
  height: 525px;
  width: 900px;
  opacity: 0.33;
  transition: 500ms ease-in-out;
}

.projectImg:hover {
  opacity: 0.7;
  width: 50%;
  height: 50%;
}

.projectsText {
  display: flex;
  flex-direction: column;
  width: 35vw;
  justify-content: center;
  max-width: 100%;

}
.projectsBox {
  display: flex;
  background-color: #141b1f;
  position: relative;
  right: 5vw;
  flex-direction: column;
  justify-content: left;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.projectInfo {
  width: fit-content;
  margin-left: 10px;
}
.linkImgs {
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 5vw;
}
.linkImgsRight {
  display: flex;
  justify-content: space-around;
  position: relative;
  left: 5vw;
}

.projects {
  display: flex;
  justify-content: center;
  margin-bottom: 85px;
}

.projectsTextRight {
  width: 35vw;
}

.projectsTextRight h3 {
  left: 5vw;
}
.projectsBoxRight {
  display: flex;
  background-color: #141b1f;
  position: relative;
  left: 5vw;
  flex-direction: column;
  justify-content: left;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  z-index: 100;
}

.gitLink {
  width: 40px;
  height: 42px;
  margin: auto 0 auto 0;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: fit-content;
  margin: 100px auto auto auto;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  margin: auto;
  justify-content: center;
}
.superContainer {
  width: 100vw;
  display: flex;
  max-width: 100%;
  height: auto;
  background-color: #141b1f;
}

@media only screen and (max-width: 1400px) {
  .projectsContainer {
    width: 100vw;
    max-width: 100%;
  }

  .h2style {
    font-size: 2em !important;
  }

  .projectsText {
    width: 100vw !important;
    margin: auto;
    justify-content: center;
    max-width: 100%;
  }

  .projectsText a {
    font-size: 2em !important;
    align-self: center;
  }
  .gitImg {
    width: 275px;
    height: 300.87px;
    margin: auto;
    max-width: 100%;
  }

  .projectImg {
    height: 420px;
    width: 720px;
    margin: auto;
    max-width: 100%;

  }

  .projectImg:hover {
    opacity: 0.7;
    width: 75%;
    height: 75%;
    max-width: 100%;
  }

  .projects {
    flex-direction: column;
    max-width: 99%;
  }

  .projectsBox {
    width: 80vw;
    right: 0vw;
    margin: auto;
    max-width: 100%;
    margin-left: 10vw;
  }
  .projectsBoxRight {
    width: 80vw !important;
    margin: auto;
    margin-left: 5vw;
    max-width: 100%;

  }

  .projectsTextRight {
    width: 100vw !important;
    order: 2;
    max-width: 100%;

  }
}

@media only screen and (max-width: 800px) {
  .aboutContainer {
    height: fit-content;
    flex-direction: column;
    margin-top: 0px;
    max-width: 100%;
  }

  .gitImg img {
    width: 252px;
    height: 324px;
    order: 1;
  }
  .list {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }

  .about {
    display: flex;
    flex-direction: column;
  }
  .h2style {
    margin: 0px;
  }

  .abText {
    margin: auto auto 45px auto;
  }

  .aboutInfo {
    width: 80vw;
    margin: auto;
  }

  .gitImg {
    width: 275px;
    height: 325.87px;
    margin: auto;
    max-width: 100%;
  }

  .projectsTitle {
    flex-direction: column;
    margin-top: 30px;
    max-width: 100%;

  }
  .gitLink {
    margin: auto;
  }

  .projectImg {
    width: 300px;
    height: 175px;
    margin: auto;
  }

  .projectImg:hover {
    opacity: 0.7;
    width: 300px;
    height: 175px;
  }

  .projects {
    flex-direction: column;
    max-width: 99%;
  }

  .projectsText {
    width: 90vw;
  }

  .projectsTextRight {
    width: 90vw;
    margin: auto;
    order: 2;
  }

  .projectsTextRight a {
    width: min-content;
  }
  .projectsTextRight h2 {
    width: min-content;
  }

  .projectsBoxRight {
    left: 0vw;
  }

  .projectsBox {
    margin: auto;
  }

  .linkImgsRight {
    left: 0vw;
  }
  .projectsTextRight h3 {
    left: 0vw;
  }
  .contactContainer {
    width: 80vw;
    margin: auto auto;
  }
}
