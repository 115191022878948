.homeContainer {
    background-color: #141B1F;
    height:auto;
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.textContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width:80vw ;
    margin:15vh auto 5vh auto;
}

.hello {
    color:#D6AA32;
    font-size: 4.5em;
    font-family:'Courier New', Courier, monospace;
    width: fit-content;
    text-align: left;
}

.name {
    color:#FFFF;
    font-size: 6em;
    font-family:Georgia, 'Times New Roman', Times, serif;
    margin: 0px;
    width: fit-content;
    margin: 5px 0 20px 0;
}

.desc {
    color:#D6AA32;
    font-size: 2em;
    font-family:'Courier New', Courier, monospace;
    margin: 0px;
    width: 70vw;
    text-align: left;
}

@media only screen and (max-width: 800px) {
    .textContainer {
        width: 80vw;
        margin:20vh auto 20vh auto;
    }

    .homeContainer {
        height: 1200px;
        justify-content: flex-start;
    }


    .hello {
        font-size: 2.5em;
        width: 80vw;
    }
    .about {
        width: 90vw;
    }

    .name{
        font-size: 2.5em;
        text-align: left;
        width: 100vw;
        max-width: 100%;
    }

    .desc {
        width: 80vw;
        font-size: 1.5em;
    }
}

